
import Vue from "vue";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import LabelPreviewDialog from "@/components/products/LabelPreviewDialog.vue";
import {
	IProduct,
	IProductDataReq,
	IProductRES,
	EMPTY_PRODUCT,
	IProductCategory
} from "@common/product";
import { EMPTY_RECIPE_INGREDIENT, IRecipeIngredient } from "@common/recipe";
import { IProductionStandardTime, EMPTY_PRODUCTION_STANDARD_TIME } from "@common/productionStandardTime";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import router from "@/router";
import { IAllergen } from "@common/allergen";
import { IIngredient } from "@common/ingredient";
import IframeDialog from "@/components/common/IframeDialog.vue";
import _ from "lodash";

export default Vue.extend({
	name: "ProductAdd",
	components: {
		"label-preview-dialog": LabelPreviewDialog,
		"iframe-dialog": IframeDialog
	},
	data: () => ({
		process: false as boolean,
		product: EMPTY_PRODUCT as IProduct,
		productionStandardTime: EMPTY_PRODUCTION_STANDARD_TIME as IProductionStandardTime,

		oldProductionStandardTime: EMPTY_PRODUCTION_STANDARD_TIME as IProductionStandardTime,
		oldProduct: EMPTY_PRODUCT as IProduct,
		recipe: [] as IRecipeIngredient[],
		allergens: [] as IAllergen[],
		pdf: {
			data: "",
			delete: false
		},
		img: {
			data: "",
			delete: false
		},
		video: {
			data: "",
			delete: false
		},
		deletedIngredients: [] as IRecipeIngredient[],
		productCategories: [] as IProductCategory[],
		ingredients: [] as IIngredient[],
		productPriorityRules: [
			(value: string) => !!value || "Required.",
			(value: string) => (parseInt(value) >= 0) || "The priority must be a number grater than 0."
		]
	}),
	created: async function () {
		this.store.dispatch.changeAppTitle("Edit product");

		if (this.store.getters.products.length === 0) {
			await this.store.dispatch.fetchProducts();
		}

		if (this.store.getters.ingredients.length === 0) {
			await this.store.dispatch.fetchIngredients();
		}

		if (this.store.getters.productionStandardTimes.length === 0) {
			await this.store.dispatch.fetchProductionStandardTimes();
		}

		this.ingredients = JSON.parse(JSON.stringify(this.store.getters.ingredients));
		this.ingredients.map((ingredient) => {
			ingredient.name = `(${ingredient.id}) ${ingredient.name}`;

			return ingredient;
		});

		if (this.store.getters.productCategories.length === 0) {
			await this.store.dispatch.fetchProductCategories();
		}

		this.productCategories = JSON.parse(JSON.stringify(this.store.getters.productCategories));
		this.productCategories.map((category) => {
			category.name = `(${category.id}) ${category.name}`;

			return category;
		});

		for (const product of this.store.getters.products) {
			if (product.id === this.$route.query.id) {
				this.oldProduct = product;
				this.product = { ...product };
			}
		}

		for (const time of this.store.getters.productionStandardTimes) {
			if (time.id === this.$route.query.id) {
				this.oldProductionStandardTime = _.cloneDeep(time);
				this.productionStandardTime = _.cloneDeep(time);
				break;
			} else if (time === undefined) {
				this.productionStandardTime = {
					minimumQuantity: 0,
					fixedValue: 0,
					variableValue: 0,
					miseEnPlace: 0,
					productId: this.product.id,
					id: this.product.id
				};
				this.oldProductionStandardTime = {
					minimumQuantity: 0,
					fixedValue: 0,
					variableValue: 0,
					miseEnPlace: 0,
					productId: this.product.id,
					id: this.product.id
				};
			} else {
				this.productionStandardTime = {
					minimumQuantity: 0,
					fixedValue: 0,
					variableValue: 0,
					miseEnPlace: 0,
					productId: this.product.id,
					id: this.product.id
				};
				this.oldProductionStandardTime = {
					minimumQuantity: 0,
					fixedValue: 0,
					variableValue: 0,
					miseEnPlace: 0,
					productId: this.product.id,
					id: this.product.id
				};
			}
		}

		await this.fetchRecipe();
	},
	computed: {
		ingredientInfos () {
			return (recipeIngredient: IRecipeIngredient) => {
				return this.store.getters.ingredients.find((ingredient) => recipeIngredient.ingredientId === ingredient.id);
			};
		}
	},
	methods: {
		addNewIngredient () {
			const newRecipeIngredient: IRecipeIngredient = {
				id: EMPTY_RECIPE_INGREDIENT.id,
				productId: this.product.id,
				ingredientId: EMPTY_RECIPE_INGREDIENT.ingredientId,
				quantity: EMPTY_RECIPE_INGREDIENT.quantity,
				printableQuantity: EMPTY_RECIPE_INGREDIENT.printableQuantity,
				position: EMPTY_RECIPE_INGREDIENT.position
			};

			if (this.store.getters.ingredients.length) {
				newRecipeIngredient.ingredientId = this.store.getters.ingredients[0].id;
			}

			this.recipe.push(newRecipeIngredient);
		},
		deleteIngredient (ingredientIdx: number) {
			if (this.recipe[ingredientIdx].id !== "") {
				this.deletedIngredients.push(this.recipe[ingredientIdx]);
			}
			this.recipe.splice(ingredientIdx, 1);
		},
		async saveProduct () {
			this.process = true;
			try {
				const data: IProductDataReq = {
					product: this.product,
					recipe: this.recipe,
					deletedIngredients: this.deletedIngredients,
					pdf: this.pdf,
					img: this.img,
					video: this.video
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/product/update`,
				};
				const res: AxiosResponse<IServerRES<IProductRES>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					const newProduct: IProduct = res.data.payload.product;
					for (const category of this.store.getters.productCategories) {
						if (category.id === newProduct.categoryId) {
							newProduct.categoryName = category.name;
						}
					}
					this.store.dispatch.editProduct({
						old: this.oldProduct,
						new: newProduct
					});
				} else {
					// TODO ERROR DISPLAY
					console.error(new Error(res.data.payload.message));
				}
			} catch (err) {
				// TODO ERROR DISPLAY
				console.error(err);
			}
			try {
				const data: IProductionStandardTime = {
					productId: this.product.id,
					id: this.product.id,
					minimumQuantity: this.productionStandardTime.minimumQuantity,
					fixedValue: this.productionStandardTime.fixedValue,
					variableValue: this.productionStandardTime.variableValue,
					miseEnPlace: this.productionStandardTime.miseEnPlace
				};
				if (this.store.getters.productionStandardTimes.find((time) => time.id === this.product.id)) {
					const options: AxiosRequestConfig = {
						method: "POST",
						headers: {
							Authorization: `Bearer ${localStorage.getItem("token")}`
						},
						data,
						url: `${this.store.getters.serverURL}/production-standard-time/update`,
					};
					const res: AxiosResponse<IServerRES<IProductionStandardTime>> = await axios(options);
					if (res.data.err === ServerError.NO_ERROR) {
						this.store.dispatch.editProductionStandardTime({
							old: this.oldProductionStandardTime,
							new: data
						});
					} else {
						console.error(new Error(res.data.payload.message));
					}
				} else {
					const options: AxiosRequestConfig = {
						method: "POST",
						headers: {
							Authorization: `Bearer ${localStorage.getItem("token")}`
						},
						data,
						url: `${this.store.getters.serverURL}/production-standard-time/add`,
					};
					const res: AxiosResponse<IServerRES<IProductionStandardTime>> = await axios(options);
					if (res.data.err === ServerError.NO_ERROR) {
						this.store.dispatch.addProductionStandardTime(data);
					} else {
						console.error(new Error(res.data.payload.message));
					}
				}
			} catch (err) {
				console.error(err);
			}

			router.push({ path: "/products" });
		},
		async fetchRecipe () {
			try {
				const data: IProduct = this.product;
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/product/recipe`,
				};
				const res: AxiosResponse<IServerRES<IRecipeIngredient[]>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					this.recipe = res.data.payload;
				} else {
					// TODO ERROR DISPLAY
					console.error(new Error(res.data.payload.message));
				}
			} catch (err) {
				// TODO ERROR DISPLAY
				console.error(err);
			}
		},
		async fetchRecipeAllergens () {
			try {
				const data: IRecipeIngredient[] = this.recipe;
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/product/recipe/allergens`,
				};
				const res: AxiosResponse<IServerRES<IAllergen[]>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					this.allergens = res.data.payload;
				} else {
					// TODO ERROR DISPLAY
					console.error(new Error(res.data.payload.message));
				}
			} catch (err) {
				// TODO ERROR DISPLAY
				console.error(err);
			}
		},
		fileChange (e: Event) {
			const target = e.target as HTMLInputElement;
			if (target.files !== null &&
							target.files.length) {
				switch (target.id) {
					case ("img_file"):
						if (target.files[0]) {
							const imgReader: FileReader = new FileReader();
							imgReader.readAsDataURL(target.files[0]);
							imgReader.onload = async () => {
								const result: string|undefined = imgReader.result?.toString().split(",")[1];
								if (result) {
									this.img.data = result;
								}
							};
						}
						break;
					case ("pdf_file"):
						if (target.files[0]) {
							const pdfReader: FileReader = new FileReader();
							pdfReader.readAsDataURL(target.files[0]);
							pdfReader.onload = async () => {
								const result: string|undefined = pdfReader.result?.toString().split(",")[1];
								if (result) {
									this.pdf.data = result;
								}
							};
						}
						break;
					case ("video_file"):
						if (target.files[0]) {
							const videoReader: FileReader = new FileReader();
							videoReader.readAsDataURL(target.files[0]);
							videoReader.onload = async () => {
								const result: string|undefined = videoReader.result?.toString().split(",")[1];
								if (result) {
									this.video.data = result;
								}
							};
						}
						break;
				}
			} else if (target.files !== null &&
							target.files.length === 0) {
				switch (target.id) {
					case ("img_file"):
						this.img.data = "";
						break;
					case ("pdf_file"):
						this.pdf.data = "";
						break;
					case ("video_file"):
						this.video.data = "";
						break;
				}
			}
		},
		async previewLabel () {
			try {
				await this.fetchRecipeAllergens();
				await (this.$refs.labelPreview as InstanceType<typeof LabelPreviewDialog>).open(this.product, this.recipe, this.allergens);
			} catch (err) {
				console.error(err);
			}
		},
		async viewPDF () {
			const URL = `${this.store.getters.serverURL}/product/files/${this.product.id}/${this.product.id}.pdf?token=${localStorage.getItem("token")}`;
			await (this.$refs.iframeDialog as InstanceType<typeof IframeDialog>).open("Product PDF", URL);
		},
		removePDF () {
			this.product.pdfHash = "";
			this.pdf.delete = true;
		},
		async viewIMG () {
			const URL = `${this.store.getters.serverURL}/product/files/${this.product.id}/${this.product.id}.png?token=${localStorage.getItem("token")}`;
			await (this.$refs.iframeDialog as InstanceType<typeof IframeDialog>).open("Product IMG", URL);
		},
		removeIMG () {
			this.product.imgHash = "";
			this.img.delete = true;
		},
		async viewVideo () {
			const URL = `${this.store.getters.serverURL}/product/files/${this.product.id}/${this.product.id}.mp4?token=${localStorage.getItem("token")}`;
			await (this.$refs.iframeDialog as InstanceType<typeof IframeDialog>).open("Product Video", URL);
		},
		removeVideo () {
			this.product.videoHash = "";
			this.video.delete = true;
		}
	}
});
